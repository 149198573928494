import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'

//banner轮播
// var interleaveOffset = 0.5; //视差比值
// var bannerswiper = new Swiper(".section1 .mySwiper", {
//   loop: true,
//   speed: 800,
//   watchSlidesProgress: true,
// 	allowTouchMove: false,
//   autoplay: {
//     delay: 4000,
//     stopOnLastSlide: false,
//     disableOnInteraction: false,
//     waitForTransition: false,
//   },
//   pagination: {// 分页
//     el: ".section1 .swiper-pagination",
//     clickable: true,
//   },
//   on:{
//     progress: function(swiper) {
// 		  for (var i = 0; i < swiper.slides.length; i++) {
// 			var slideProgress = swiper.slides[i].progress;
// 			var innerOffset = swiper.width * interleaveOffset;
// 			var innerTranslate = slideProgress * innerOffset;
// 			swiper.slides[i].querySelector(".slide-inner").style.transform =
// 			  "translate3d(" + innerTranslate + "px,0, 0)";
// 		  }      
// 		},
// 		touchStart: function(swiper) {
// 		  for (var i = 0; i < swiper.slides.length; i++) {
// 			swiper.slides[i].style.transition = "";
// 		  }
// 		},
// 		setTransition: function(swiper, speed) {
// 		  for (var i = 0; i < swiper.slides.length; i++) {
// 			swiper.slides[i].style.transition = speed + "ms";
// 			swiper.slides[i].querySelector(".slide-inner").style.transition =
// 			  speed + "ms";
// 		  }
// 		}
//   },
// })

var swiper2 = new Swiper(".section4 .mySwiper", {
	speed: 800,
	effect : 'fade',
	allowTouchMove: false,
	// autoplay: { // 自动切换
  //   delay: 5000,
  //   stopOnLastSlide: false,
  //   disableOnInteraction: false,
  //   waitForTransition: false,
  //   pauseOnMouseEnter: true,
  // },
	navigation: {//按钮
    nextEl: ".section4 .swiper-button-next",
    prevEl: ".section4 .swiper-button-prev",
  },
	on:{
		init: function(swiper){
      $('.section4 .sect-tab .text a').eq(this.realIndex).addClass('hover')
    },
    slideChangeTransitionStart: function(){
      $('.section4 .sect-tab .text a').eq(this.realIndex).addClass('hover').siblings().removeClass('hover')
    },
	}
})

$('.section4 .sect-tab .text a').hover(function(){
	let index = $(this).index()
	swiper2.slideTo(index)
})

$(document).ready(function(){
	topCircle()
	if($(window).width() >= 768){
		setInterval(function(){
			topCircle()
		},10000)
	}else{
		setInterval(function(){
			topCircle()
		},4000)
	}
	

	// if($(window).width() > 1024){
	// 	$('.section3').height($('.section3 .cont ul').outerWidth() - $(window).width() + $(window).height());
	// }else{
	// 	$('.section3').css('height','auto')
	// }

	// $('.section3 .cont ul li').eq(0).addClass('hover')
	// $('.section3 .cont ul li').hover(function(){
	// 	$(this).addClass('hover').siblings().removeClass('hover')
	// })

})

function getRandomInt() {
	let min,max
	if($(window).width()>1024){
		min = Math.ceil(-200);
  	max = Math.floor(200);
	}else if($(window).width()>640 && $(window).width()<=1024){
		min = Math.ceil(-100);
  	max = Math.floor(100);
	}else{
		min = Math.ceil(-50);
  	max = Math.floor(50);
	}
  
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
function topCircle(){
	$('.top-circle .circle1').css({
		marginTop : getRandomInt() + 'px',
		marginLeft : getRandomInt() + 'px',
	})
	$('.top-circle .circle2').css({
		marginTop : getRandomInt() + 'px',
		marginLeft : getRandomInt() + 'px',
	})
	$('.top-circle .circle3').css({
		marginTop : getRandomInt() + 'px',
		marginLeft : getRandomInt() + 'px',
	})
	// $('.section3 .circle1').css({
	// 	marginTop : getRandomInt() + 'px',
	// 	marginLeft : getRandomInt() + 'px',
	// })
	// $('.section3 .circle2').css({
	// 	marginTop : getRandomInt() + 'px',
	// 	marginLeft : getRandomInt() + 'px',
	// })
}

//页面滚动监听
$(document).scroll(function () {
  let scroH = $(document).scrollTop();
  let viewH = $(window).height();
	let viewW = $(window).width();

	// let proTop = $('.section3').offset().top
	let solutionsTop = $('.section4').offset().top
	let newsTop = $('.section5').offset().top - viewH

  // if(viewW > 1024){
  //   $('.section3').height($('.section3 .cont ul').outerWidth() - viewW + viewH);

  //   if(scroH > proTop && scroH < solutionsTop - viewH){
  //     $('.section3 .cont').addClass('fixed').removeClass('abs')
  //     $('.section3 .cont ul').css('transform', 'translateX(-' + (scroH - proTop) + 'px)')
  //   }else if(scroH >= solutionsTop - viewH){
  //     $('.section3 .cont').removeClass('fixed').addClass('abs')
  //   }else{
  //     $('.section3 .cont').removeClass('fixed').removeClass('abs')
  //   }
  // }else{
	// 	$('.section3').css('height','auto')
	// 	$('.section3 .cont ul').css('transform', 'translateX(0px)')
	// }

	// let proTop2 = $('.section3').offset().top - viewH
	// if(scroH > proTop2){
	// 	let bgX = (scroH-proTop2)/50
	// 	let bgY = (scroH-proTop2)/3
	// 	$('.section3 .bg').css({
	// 		marginTop : bgY + 'px',
	// 		marginLeft : -bgX + 'px',
	// 	})
	// }


	if(scroH > solutionsTop - viewH/2){
		$('.section4 .sect-tab').addClass('active')
	}else{
		$('.section4 .sect-tab').removeClass('active')
	}

	if(scroH > newsTop){
		$('.section5 .bg').css('transform', 'translateY(' + (scroH - newsTop)/4 + 'px)')
	}


});